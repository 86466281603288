<template>
  <div class="show-img-wrapper">
    <div v-if="imgSrc" class="_pic">
      <img :src="imgSrc" alt="" @click="make_jumpUrl( )">
    </div>
  </div>
</template>

<script>
import page_links from "../../mixin/page_wx";
import wx from "weixin-js-sdk";

export default {
  components: {},
  mixins: [page_links],
  data() {
    return {
      imgSrc: '',
      linkUrl: "",
      id: ""
    };
  },

  created() {
  },

  mounted() {
    this.getImageShowData();

  },

  methods: {
    make_jumpUrl() {
      this.onClickPageLink(this.linkUrl)
    },
    async getImageShowData() {
      this.id = this.$route.query.id;
      if (this.id) {
        let {data, code} = await this.$ajax.gateway("/api/api_get_resource_by_id", {
          "METHOD": "get",
          "id": this.id
        });
        if (code === 200) {
          this.imgSrc = data.resources_img;
          this.linkUrl = data.link_url;
        }
        await this.getJsInfo();
      }
    },
    async getJsInfo() {
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/api_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        wx.config(data.res);
      }
      let shareInfo = {
        title: "寻味成华美食指南", // 分享标题
        desc: "2024品味成都·食在成华·地道美食精选", // 分享描述
        link: 'https://chqmsdt.eaworks.cn/page',
        imgUrl: 'https://pic.eaworks.cn/chenghua/1.png',
        success: function (res) {
          // 设置成功
        }
      }

      if (this.id === "63737418-ceb3-41b5-8857-d80e07ec0ccd") {
        shareInfo = {
          title: "寻味成华-微醺图鉴", // 分享标题
          desc: "日饮夜饮 前程似锦", // 分享描述
          link: 'https://chqmsdt.eaworks.cn/page#/showImg?id=63737418-ceb3-41b5-8857-d80e07ec0ccd',
          imgUrl: 'https://pic.eaworks.cn/chenghuaqu/wxtj.png',
          success: function (res) {
            // 设置成功
          }
        }
      }
      if (this.id === "08932d7e-aea9-4788-b369-bd41a8bed544") {
        shareInfo = {
          title: "寻味成华-吃货图鉴", // 分享标题
          desc: "知食份子 加载中", // 分享描述
          link: 'https://chqmsdt.eaworks.cn/page#/showImg?id=08932d7e-aea9-4788-b369-bd41a8bed544',
          imgUrl: 'https://pic.eaworks.cn/chenghuaqu/chtj.png',
          success: function (res) {
            // 设置成功
          }
        }
      }
      wx.ready(function () {
        wx.updateAppMessageShareData(shareInfo);
        wx.updateTimelineShareData(shareInfo);
      });
      wx.error(function (res) {
        console.info("wx error", res);
      });
    }
  }
};
</script>

<style lang="less" scoped>

.show-img-wrapper {
  ._pic {
    img {
      display: block;
      width: 100%;
    }
  }

  height: 100%;
  background: #ebebeb no-repeat;
}
</style>
